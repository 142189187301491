import Vue from 'vue';
import Router from 'vue-router';
import { DEVICE_TYPE, DEVICE_TYPE_NAMES, deviceType, helper } from '@/modules/core';

import { mutation as paymentMutation } from '@/modules/payment/store/const';
import { WithdrawPage } from '@/modules/payment/components';

import { action as betslipAction } from '@/modules/sport/store/modules/betslip/const';
import { mutation as sportMutation } from '@/modules/sport/store/const';

import { isSibling } from '@/modules/platform';
import { action as authAction, getter as authGetter, mutation as authMutation } from '@/modules/platform/store/modules/auth/const';
import { action as messagingAction } from '@/modules/platform/store/modules/messaging/const';
import { action as platformAction, getter as platformGetter, mutation as platformMutation } from '@/modules/platform/store/const';

import store from '@/store/store';
import { action, mutation } from '@/store/const';
import { getter as translationsGetter, action as translationsAction } from '@/store/modules/translations/const';

import redirections from './redirections';
import { routeName } from '@/router/const-name';
import { routePath } from '@/router/const-path';
import { createConditionalComponent } from '@/router/utils';
import { CasinoFilter } from '@/modules/casino/utils/CasinoCollection-const';
import { defaultCasinoPath, defaultCasinoTitle } from '@/modules/platform/const/casino-const';
import CasinoGameDetails from '@/modules/casino/pages/CasinoGameDetails.vue';
import CasinoAllGames from '@/modules/casino/pages/CasinoAllGames.vue';
import { getObjectField } from '@/modules/core/utils/helper';

const EventView = () => import(/* webpackChunkName: "event-view-page" */ '@/modules/sport/components/Pages/EventView');
const JackpotPage = () => import(/* webpackChunkName: "jackpot-page" */ '@/modules/sport/components/Pages/JackpotPage');
const JackpotTicket = () => import(/* webpackChunkName: "jackpot-ticket-page" */ '@/modules/sport/components/Pages/JackpotTicket');
const MyBetsView = () => import(/* webpackChunkName: "my-bets-page" */ '@/modules/sport/components/Pages/MyBetsView');
const SearchView = () => import(/* webpackChunkName: "search-page" */ '@/modules/sport/components/Pages/SearchView');
const SingleBetslipView = () => import(/* webpackChunkName: "single-betslip-page" */ '@/modules/sport/components/Pages/SingleBetslipView');
const SportsView = () => import(/* webpackChunkName: "sports-page" */ '@/modules/sport/components/Pages/SportsView');
const VirtualMatchdayView = () =>
    import(/* webpackChunkName: "virtual-match-day-page" */ '@/modules/sport/components/Pages/Virtual/VirtualMatchday');
const VirtualSportsView = () => import(/* webpackChunkName: "virtual-sports-page" */ '@/modules/sport/components/Pages/VirtualSportsView');
const BoostedEventsView = () => import(/* webpackChunkName: "boosted-events-page" */ '@/modules/sport/components/Pages/BoostedEventsView');

const AccountVerification = () =>
    import(/* webpackChunkName: "account-verification-page" */ '@/modules/platform/components/Pages/AccountVerification');
const ChangePassword = () => import(/* webpackChunkName: "change-password-page" */ '@/modules/platform/components/Pages/ChangePassword');
const JoinNowPage = () => import(/* webpackChunkName: "join-now-page" */ '@/modules/platform/components/Pages/JoinNowPage');
const LoginPageV2 = () => import(/* webpackChunkName: "login-v2-page" */ '@/modules/platform/components/Pages/LoginPageV2');
const Messages = () => import(/* webpackChunkName: "messages-page" */ '@/modules/platform/components/Pages/Messages');
const MyAccount = () => import(/* webpackChunkName: "my-account-page" */ '@/modules/platform/components/Pages/MyAccount');
const ResetPassword = () => import(/* webpackChunkName: "reset-password-page" */ '@/modules/platform/components/Pages/ResetPassword');
const SetPassword = () => import(/* webpackChunkName: "set-password-page" */ '@/modules/platform/components/Pages/SetPassword');
const StatementView = () => import(/* webpackChunkName: "statements-page" */ '@/modules/platform/components/Pages/StatementView');

const LandingPage = () => import(/* webpackChunkName: "landing-page" */ '@/components/Pages/LandingPage');
const EventNotFound = () => import(/* webpackChunkName: "event-not-found" */ '@/components/Pages/EventNotFound');
const GenericPage = () => import(/* webpackChunkName: "generic-page" */ '@/components/Pages/GenericPage');
const RulesPage = () => import(/* webpackChunkName: "rules-page" */ '@/components/Pages/RulesPage');
const PageNotFound = () => import(/* webpackChunkName: "not-found-page" */ '@/components/Pages/PageNotFound');
const ExternalPrefillPage = () => import(/* webpackChunkName: "external-prefill-page" */ '@/components/Pages/ExternalPrefillPage');
const CasinoDeviceErrorPage = () => import(/* webpackChunkName: "external-prefill-page" */ '@/components/Pages/CasinoDeviceErrorPage');

const pinOrPassword = helper.capitalize(store.getters[translationsGetter.PIN_OR_PASSWORD]);

const resolveCasinoFn = (route) => {
    const enableNewCasino = store.getters[platformGetter.GET_BRAND_PREFERENCE].enableNewCasinoVTwo;
    if (deviceType.isPresto()) return CasinoDeviceErrorPage;
    if (!enableNewCasino) return GenericPage;

    const query = route.query;
    let componentToLoad = GenericPage;
    const isGameDetails = !!query[CasinoFilter.GAME_ID];
    const isCasinoLobby = Object.entries(query).some(([key, value]) => Object.values(CasinoFilter).includes(key) && !!value);
    if (isGameDetails) {
        componentToLoad = CasinoGameDetails;
    } else if (isCasinoLobby) {
        componentToLoad = CasinoAllGames;
    }
    return componentToLoad;
};

const casinoRedirect = () => {
    const { path: casinoValidPath } = store.getters[platformGetter.GET_BRAND_PREFERENCE].casinoType || {};
    const pagePathKey = (casinoValidPath || defaultCasinoPath).toUpperCase();
    const casinoRoutePath = routePath[pagePathKey];
    const casinoInvalidPath = [routePath.CASINO, routePath.GAMES].find((i) => i !== casinoRoutePath);
    return {
        path: casinoInvalidPath,
        redirect: (to) => {
            if (to.path.includes(casinoRoutePath)) {
                return undefined;
            }

            return {
                ...to,
                name: routeName[pagePathKey],
                path: casinoRoutePath,
            };
        },
    };
};

const CASINO_PAGE_ROUTE = {
    component: createConditionalComponent(resolveCasinoFn),
    meta: {
        moduleSwitch: ['brandGamingIntegration.enabled'],
        seo: {
            pageTitle: defaultCasinoTitle,
        },
        forceBalanceCheck: true,
        useCustomStyle: true,
    },
    beforeEnter: (to, from, next) => {
        const { pageTitle } = store.getters[platformGetter.GET_BRAND_PREFERENCE].casinoType || {};
        to.meta.seo.pageTitle = pageTitle || defaultCasinoTitle;
        next();
    },
};
const setRetainScrollMeta = ({ to, from, retainFromRoute, elementSelector }) => {
    if (!deviceType.isPresto() && to.meta?.isClickedBack && from.name === retainFromRoute) {
        to.meta.scrollSelector = elementSelector;
    }
};

function prefixRoutes(prefix, routes) {
    return routes.map((route) => {
        route.path = prefix + route.path;
        return route;
    });
}

Vue.use(Router);

const router = new Router({
    mode: 'history',
    scrollBehavior: handleScrollBehaviour,
    routes: [
        ...redirections,
        {
            path: routePath.HOMEPAGE,
            name: routeName.HOMEPAGE,
            component: GenericPage,
            beforeEnter: (to, from, next) => {
                const { bookingCode } = to.query;
                if (bookingCode) {
                    store.dispatch(betslipAction.LOAD_BOOKING_CODE_BY_QUERY, bookingCode);
                    next({ path: to.path });
                } else {
                    next();
                }
            },
            props: {
                slotId: 'HOME_PAGE_COMPONENT',
            },
            meta: {
                seo: {
                    template: 'main',
                },
            },
        },
        {
            path: routePath.DEPOSIT,
            name: routeName.DEPOSIT,
            component: GenericPage,
            beforeEnter: (to, from, next) => {
                const isPrestoPageAvailable = deviceType.isPresto() && store.getters[platformGetter.COUNTRY_CODE_IS].NG;
                if (isPrestoPageAvailable) {
                    next(routePath.DEPOSIT_PRESTO);
                } else {
                    next();
                }
            },
            meta: {
                seo: {
                    template: 'other',
                    page: 'deposit',
                },
                requireRedirect: !store.getters[platformGetter.COUNTRY_CODE_IS].NG,
                redirect: ({ from }) => {
                    const newRoute = {};
                    const newHref = store.getters[platformGetter.GET_DEPOSIT_PAGE_LINK];
                    const { skipDepositRedirect } = from.meta || {};

                    if (!skipDepositRedirect && newHref && newHref !== routePath.DEPOSIT) {
                        newRoute.path = newHref;
                    }
                    return newRoute;
                },
            },
        },
        {
            path: routePath.DEPOSIT_PRESTO,
            name: routeName.DEPOSIT, // note: better use different name, because no ability to redirect deposit-pesto by route name (see DEPOSIT route above)
            alias: routePath.DEPOSIT,
            component: GenericPage,
            meta: {
                seo: {
                    template: 'other',
                    page: 'deposit',
                },
            },
        },
        {
            path: routePath.LANDING,
            name: routeName.LANDING,
            component: LandingPage,
            meta: {
                seo: {
                    template: 'main',
                },
            },
        },
        {
            path: routePath.EVENT,
            name: routeName.EVENT,
            component: EventView,
            meta: {
                seo: {
                    skipOnCreated: true,
                },
            },
        },
        {
            path: `${routePath.PREVIEW}/:id`,
            name: routeName.PREVIEW,
            component: GenericPage,
            props: {
                isPreviewRoute: true,
            },
        },
        {
            path: routePath.EVENT_NOT_FOUND,
            name: routeName.EVENT_NOT_FOUND,
            component: EventNotFound,
            meta: {
                seo: {
                    skipOnCreated: true,
                },
            },
        },
        {
            path: routePath.LOGIN,
            name: routeName.LOGIN,
            component: LoginPageV2,
            meta: {
                requireAuth: false,
                redirect: { path: routePath.HOMEPAGE },
                seo: {
                    template: 'login',
                    page: 'login',
                },
            },
        },
        {
            path: routePath.JOIN_NOW,
            name: routeName.JOIN_NOW,
            component: JoinNowPage,
            meta: {
                requireAuth: false,
                redirect: { path: routePath.HOMEPAGE },
                seo: {
                    template: 'joinNow',
                    page: 'join-now',
                },
            },
        },
        {
            path: routePath.LOGOUT,
            name: routeName.LOGOUT,
            meta: {
                skipBalanceCheck: true,
            },
            beforeEnter(to, from, next) {
                store.dispatch(authAction.LOGOUT).finally(() => next({ name: to.query?.nextPath || routeName.HOMEPAGE }));
            },
        },
        {
            path: routePath.MY_ACCOUNT,
            name: routeName.MY_ACCOUNT,
            component: MyAccount,
            meta: {
                requireAuth: true,
                redirect: {
                    path: routePath.LOGIN,
                    query: { returnPath: routePath.MY_ACCOUNT },
                },
                seo: {
                    skipOnCreated: true,
                },
            },
        },
        {
            path: routePath.CHANGE_PASSWORD,
            name: routeName.CHANGE_PASSWORD,
            component: ChangePassword,
            meta: {
                requireAuth: true,
                redirect: {
                    path: routePath.LOGIN,
                    query: { returnPath: routePath.CHANGE_PASSWORD },
                },
                seo: {
                    template: 'other',
                    page: 'change' + pinOrPassword,
                },
            },
        },
        {
            path: routePath.STATEMENT,
            name: routeName.STATEMENT,
            component: StatementView,
            meta: {
                requireAuth: true,
                redirect: {
                    path: routePath.LOGIN,
                    query: { returnPath: routePath.STATEMENT },
                },
                seo: {
                    template: 'statement',
                    page: 'statement',
                },
                forceBalanceCheck: true,
            },
        },
        {
            path: routePath.MESSAGES,
            name: routeName.MESSAGES,
            component: Messages,
            meta: {
                requireAuth: true,
                redirect: {
                    path: routePath.LOGIN,
                    query: { returnPath: routePath.MESSAGES },
                },
                seo: {
                    template: 'messages',
                    page: 'messages',
                },
            },
        },
        ...prefixRoutes('/events', [
            {
                path: `${routePath.COUNTRY}/:id?`, // note: optional to avoid empty countryPath in SEO.mixin ld+json breadcrumbList, previous was "/country?id=" - wrong, but always is, seems like issue
                name: routeName.COUNTRY,
                component: SportsView,
                props: {
                    seoHandler: ({ country, id, sport }) => ({
                        country,
                        countryId: id,
                        sport,
                    }),
                    skipSetSEOTags: false,
                },
                meta: {
                    seo: {
                        skipOnCreated: true,
                    },
                    showEventFilters: true,
                },
                beforeEnter(to, from, next) {
                    setRetainScrollMeta({
                        to,
                        from,
                        retainFromRoute: 'Event',
                        elementSelector: `[data-event-id="${from.params.id}"]`,
                    });
                    next();
                },
            },
            {
                path: `${routePath.GROUP}/:id`,
                name: routeName.GROUP,
                component: SportsView,
                props: {
                    seoHandler: ({ id, regionId, league, country, sport }) => ({
                        league,
                        leagueId: id,
                        sport,
                        country,
                        countryId: regionId,
                    }),
                    skipSetSEOTags: false,
                },
                meta: {
                    seo: {
                        skipOnCreated: true,
                    },
                    showEventFilters: true,
                },
                beforeEnter(to, from, next) {
                    setRetainScrollMeta({
                        to,
                        from,
                        retainFromRoute: 'Event',
                        elementSelector: `[data-event-id="${from.params.id}"]`,
                    });
                    next();
                },
            },
            {
                path: '',
                name: routeName.UPCOMING,
                component: SportsView,
                props: {
                    skipSetSEOTags: true,
                    tabsAligned: true,
                },
                meta: {
                    seo: {
                        template: 'upcoming',
                        page: 'upcoming',
                    },
                    showEventFilters: true,
                },
                beforeEnter(to, from, next) {
                    setRetainScrollMeta({
                        to,
                        from,
                        retainFromRoute: 'Event',
                        elementSelector: `[data-event-id="${from.params.id}"]`,
                    });
                    next();
                },
            },
            {
                path: routePath.POPULAR,
                name: routeName.POPULAR,
                component: SportsView,
                meta: {
                    seo: {
                        template: 'popular',
                        page: 'popular',
                    },
                    showEventFilters: true,
                    disableUpcomingRedirect: true,
                },
                beforeEnter(to, from, next) {
                    setRetainScrollMeta({
                        to,
                        from,
                        retainFromRoute: 'Event',
                        elementSelector: `[data-event-id="${from.params.id}"]`,
                    });
                    next();
                },
            },
            {
                path: routePath.LIVE,
                name: routeName.LIVE,
                component: deviceType.isPresto() ? GenericPage : SportsView,
                props: {
                    params: {
                        sort: (event) => event.additionalInfo?.live,
                    },
                },
                meta: {
                    useCustomStyle: true,
                    seo: {
                        template: 'live',
                    },
                    showEventFilters: true,
                    disableUpcomingRedirect: true,
                },
            },
            {
                path: routePath.LIVE_CATEGORY,
                name: routeName.LIVE_CATEGORY,
                component: GenericPage,
                meta: {
                    useCustomStyle: true,
                    seo: {
                        template: 'liveCategory',
                    },
                },
                beforeEnter(to, from, next) {
                    to.params.pathMatch = to.path;
                    to.params.category = to.path.replace('/live-', '');
                    next();
                },
            },
            {
                path: routePath.BOOSTED,
                name: routeName.BOOSTED_EVENTS,
                component: BoostedEventsView,
                beforeEnter(to, from, next) {
                    setRetainScrollMeta({
                        to,
                        from,
                        retainFromRoute: 'Event',
                        elementSelector: `[data-event-id="${from.params.id}"]`,
                    });
                    next();
                },
                meta: {
                    seo: {
                        page: 'boosted',
                    },
                },
            },
        ]),
        {
            path: routePath.VIRTUAL_SPORTS,
            name: routeName.VIRTUAL_SPORTS,
            component: VirtualSportsView,
            meta: {
                isVirtual: true,
                moduleSwitch: ['virtuals.enabled'],
                seo: {
                    template: 'virtual',
                    page: 'virtual',
                },
            },
        },
        {
            path: `${routePath.MATCH_DAY}/:seasonId/:roundId`,
            name: routeName.MATCH_DAY,
            component: VirtualMatchdayView,
            meta: {
                isVirtual: true,
                backButtonPathName: routeName.VIRTUAL_SPORTS,
                seo: {},
            },
        },
        {
            path: routePath.JACKPOT_PAGE,
            name: routeName.JACKPOT_PAGE,
            component: JackpotPage,
            props: {
                slotId: ['ACTIVE_JACKPOT_PAGE', 'RESULTS_JACKPOT_PAGE'],
            },
            meta: {
                seo: {
                    template: 'jackpot',
                    page: 'jackpot',
                },
            },
        },
        {
            path: routePath.SEARCH,
            name: routeName.SEARCH,
            component: SearchView,
            meta: {
                seo: {
                    template: 'other',
                    page: 'search',
                },
            },
        },
        {
            path: `${routePath.MY_BETS}/:section?`,
            name: routeName.MY_BETS,
            component: MyBetsView,
            meta: {
                requireAuth: true,
                redirect: (route) => ({
                    path: routePath.LOGIN,
                    query: { returnPath: route.to.path },
                }),
                seo: {
                    template: 'myBets',
                    page: 'myBets',
                },
            },
        },
        {
            path: `${routePath.JACKPOT_TICKET}/:ticketId`,
            name: routeName.JACKPOT_TICKET,
            component: JackpotTicket,
            meta: {
                requireAuth: true,
                redirect: (route) => ({
                    path: routePath.LOGIN,
                    query: { returnPath: route.to.path },
                }),
                backButtonPathName: 'My Bets',
                seo: {
                    template: 'other',
                    page: 'placedJackpotTicket',
                },
            },
        },
        {
            path: `${routePath.BETSLIP}/:betslipType?/:id/`,
            name: routeName.BETSLIP,
            component: SingleBetslipView,
            meta: {
                requireAuth: true,
                redirect: (route) => ({
                    path: routePath.LOGIN,
                    query: { returnPath: route.to.path },
                }),
                backButtonPathName: 'My Bets',
                seo: {
                    template: 'other',
                    page: 'placedBetslip',
                },
            },
        },
        {
            path: `${routePath.SNAPSHOT_BETSLIP}/:betslipType?/:id`,
            name: routeName.SNAPSHOT_BETSLIP,
            component: SingleBetslipView,
            meta: {
                backButtonPathName: 'My Bets',
                seo: {
                    template: 'other',
                    page: 'placedBetslip',
                },
            },
        },
        {
            path: `${routePath.ACCOUNT_VERIFICATION}/:phoneNumber?`,
            name: routeName.ACCOUNT_VERIFICATION,
            component: AccountVerification,
            props: {
                isPasswordRequired: false,
                changePhoneRoute: routeName.JOIN_NOW,
            },
            meta: {
                requireAuth: false,
                redirect: { path: routePath.HOMEPAGE },
                seo: {
                    template: 'other',
                    page: 'accountVerification',
                },
            },
        },
        {
            path: `${routePath.RESET}/:phoneNumber?`,
            name: routeName.RESET,
            component: AccountVerification,
            props: (route) => ({
                isAccountVerification: false,
                verificationCodeSent: route.params.verificationCodeSent,
                isVerificationModalOpened: route.params.isVerificationModalOpened,
                changePhoneRoute: routeName.RESET_PASSWORD,
            }),
            meta: {
                requireAuth: false,
                redirect: { path: routePath.HOMEPAGE },
                seo: {
                    template: 'other',
                    page: 'reset' + pinOrPassword,
                },
            },
        },
        {
            path: `${routePath.SMS_VERIFICATION}/:hash`,
            name: routeName.SMS_VERIFICATION,
            component: AccountVerification,
            props: (route) => ({ smsVerification: route.params.hash, isPasswordRequired: false }),
        },
        {
            path: `${routePath.RESET_PASSWORD}/:phoneNumber?`,
            name: routeName.RESET_PASSWORD,
            component: ResetPassword,
            meta: {
                requireAuth: false,
                redirect: { path: routePath.HOMEPAGE },
                seo: {
                    template: 'other',
                    page: 'reset' + pinOrPassword,
                },
            },
        },
        {
            path: routePath.SET_PASSWORD,
            name: routeName.SET_PASSWORD,
            component: SetPassword,
            meta: {
                requireAuth: true,
                redirect: {
                    path: routePath.LOGIN,
                    query: { returnPath: routePath.SET_PASSWORD },
                },
                seo: {
                    template: 'other',
                    page: 'set' + pinOrPassword,
                },
            },
        },
        {
            path: routePath.VERIFY_PAGE,
            name: routeName.VERIFY_PAGE,
            component: GenericPage,
            meta: {
                seo: {
                    template: 'other',
                },
            },
        },
        {
            ...casinoRedirect(),
        },
        {
            path: routePath.GAMES,
            name: routeName.GAMES,
            ...CASINO_PAGE_ROUTE,
        },
        {
            path: routePath.CASINO,
            name: routeName.CASINO,
            ...CASINO_PAGE_ROUTE,
        },
        {
            path: routePath.CASINO_GAMES_DETAILS,
            name: routeName.CASINO_GAMES_DETAILS,
            ...CASINO_PAGE_ROUTE,
        },
        {
            path: routePath.CASINO_GAME,
            name: routeName.CASINO_GAME,
            beforeEnter(to) {
                window.location.pathname = to.path;
            },
            meta: {
                allowedDeviceType: [DEVICE_TYPE_NAMES.WEB, DEVICE_TYPE_NAMES.APP, DEVICE_TYPE_NAMES.SMART_PHONE],
                redirect: {
                    path: routePath.GAMES,
                },
                skipBalanceCheck: true,
            },
        },
        {
            path: routePath.PAWA6,
            name: routeName.PAWA6,
            meta: {
                query: {
                    language: store.getters[translationsGetter.GET_SELECTED_LANGUAGE],
                },
                isExternal: true,
            },
            beforeEnter(to, from, next) {
                const { isPawaSixEnabled } = store.getters[platformGetter.GET_BRAND_PREFERENCE];
                if (isPawaSixEnabled) {
                    next();
                } else {
                    next(routePath.HOMEPAGE);
                }
            },
        },
        {
            path: routePath.RULES,
            name: routeName.RULES,
            component: GenericPage,
            beforeEnter(to, from, next) {
                if (deviceType.isPresto()) {
                    next({ name: 'Rules presto' });
                } else {
                    next();
                }
            },
            meta: {
                seo: {
                    template: 'rules',
                    page: 'rules',
                },
            },
        },
        {
            path: routePath.RULES_PRESTO,
            name: routeName.RULES_PRESTO,
            component: RulesPage,
            meta: {
                seo: {
                    template: 'rules',
                    page: 'rules',
                },
            },
        },

        // ------------ START OF UNKNOWN -----------------------
        {
            path: routePath.MTN_AIRTIME_BANNER, // Page or content ?
            name: routeName.MTN_AIRTIME_BANNER,
            component: GenericPage,
            props: {
                slotId: 'MTN_AIRTIME_BANNER', // EMPTY
            },
        },
        // ------------------- END OF UNKNOWN -----------------------------
        {
            path: routePath.WITHDRAW,
            name: routeName.WITHDRAW,
            component: WithdrawPage,
            meta: {
                requireAuth: true,
                redirect: {
                    path: routePath.LOGIN,
                    query: { returnPath: routePath.WITHDRAW },
                },
                seo: {
                    template: 'withdraw',
                    page: 'withdraw',
                },
            },
            beforeEnter(to, from, next) {
                store.commit(paymentMutation.SET_EXPERIMENTAL_PAYOUTS, false);
                next();
            },
        },
        {
            path: routePath.WITHDRAW_DISABLED,
            name: routeName.WITHDRAW_DISABLED,
            component: WithdrawPage,
            beforeEnter(to, from, next) {
                store.commit(paymentMutation.SET_EXPERIMENTAL_PAYOUTS, true);
                next();
            },
        },
        {
            path: routePath.NOT_FOUND,
            name: routeName.NOT_FOUND,
            component: PageNotFound,
            props: {
                slotId: 'ERROR_PAGE_404',
            },
            meta: {
                seo: {
                    template: 'other',
                    page: '404',
                },
            },
        },
        {
            path: routePath.CASHOUT_LOCKED,
            name: routeName.CASHOUT_LOCKED,
            component: GenericPage,
            meta: {
                seo: {
                    template: 'cashoutLocked',
                },
            },
        },
        {
            path: routePath.EXTERNAL_PREFILL,
            name: routeName.EXTERNAL_PREFILL,
            component: ExternalPrefillPage,
        },
        {
            path: routePath.GENERIC_PAGE,
            name: routeName.GENERIC_PAGE,
            component: GenericPage,
        },
    ],
});

// TODO: upcoming vue router should have a built in back detection, update router and refactor the guard
// https://github.com/vuejs/vue-router/issues/2571
let currentTimestamp = 0;
let reopenBetslip = false;

// Global route guard
router.beforeEach((to, from, next) => {
    if (to.meta?.isExternal) {
        const url = new URL(window.location);
        const requiredParams = Object.entries(to.meta?.query || {});
        const missParams = requiredParams.filter(([k, v]) => !url.searchParams.has(k));

        if (missParams.length) {
            url.pathname = to.path;
            url.searchParams.delete('r');
            url.searchParams.delete('returnPath');
            missParams.forEach(([key, value]) => {
                url.searchParams.set(key, value);
            });

            window.location = url.toString();
            return false;
        }
    }

    if (to.name !== routeName.HOMEPAGE && store.getters[authGetter.IS_SESSION_EXPIRED]) {
        store.commit(authMutation.SET_SESSION_EXPIRED, false);
        return next(false);
    }

    store.commit(sportMutation.SET_SPORT_TYPE, to.meta?.isVirtual);
    store.commit(mutation.CLOSE_TOP_MENU);

    const { categoryId } = to.params;
    const marketTypeFilters = store.getters[platformGetter.GET_MARKET_TYPE_FILTERS];
    if (categoryId && marketTypeFilters.some(({ category }) => category === categoryId)) {
        store.commit(mutation.SET_CURRENT_CATEGORY, categoryId);
    }

    // back button detection
    if (!history.state) {
        history.replaceState({ key: Date.now() }, to.name, location.href);
    }
    const isClickedBack = currentTimestamp > Number(history.state.key);

    if (to.meta) {
        to.meta.isClickedBack = isClickedBack;
        to.meta.scrollSelector = undefined;
    }

    if (isClickedBack) {
        if (store.state.ui.sidebarOpen && Vue.$mq.isXMedium) {
            store.dispatch(action.TOGGLE_SIDEBAR);
            return next(false);
        }
        if (reopenBetslip) {
            store.dispatch(action.SET_BETSLIP_STATE, true);
        }
    } else {
        if (!reopenBetslip) {
            reopenBetslip = store.state.ui.betslipOpen;
        } else {
            reopenBetslip = false;
        }
        // #top is the Presto way to scroll up to the betslip
        if (to.hash !== '#top') {
            store.dispatch(action.SET_BETSLIP_STATE, false);
        }
    }
    const { brand } = store.getters[platformGetter.GET_SETTINGS];

    if (!brand || !Object.keys(brand).length) {
        // a hack to set deviceType header for devices where android app is not able to set window flags on time
        if (DEVICE_TYPE !== DEVICE_TYPE_NAMES.APP && deviceType.isApp()) {
            Vue.$httpBase.setHeaders({ deviceType: DEVICE_TYPE_NAMES.APP });
        }
        let localComponentData = {};
        let errors = [];
        /**
         * Get UI configuration
         */
        store
            .dispatch(platformAction.GET_CONFIG)
            .then((strapi) => {
                const { brandIdentifier } = getObjectField(strapi[0], 'attributes.brandPreferences');
                /**
                 * Set `x-pawa-brand` header for making BE requests
                 */
                Vue.$httpBase.setHeaders({ 'x-pawa-brand': brandIdentifier });
                /**
                 * Get settings for brand
                 */
                return store.dispatch(platformAction.GET_COMPONENT_DATA).then((componentData) => {
                    localComponentData = componentData;
                    /**
                     * Combines UI configuration and settings.
                     */
                    return store.dispatch(platformAction.SET_CONFIG, { strapi, componentData });
                });
            })
            .catch((e) => errors.push(e))
            .finally(() => {
                /**
                 * Locales are set and ready to fetch locale json file.
                 */
                store
                    .dispatch(translationsAction.GET_TRANSLATIONS)
                    .catch((e) => errors.push(e))
                    .finally(() => {
                        if (errors.length) {
                            /**
                             * Blocks application when errored.
                             */
                            store.commit(platformMutation.SET_SETTINGS_ERROR, errors);
                        } else {
                            /**
                             * Finally sets brand settings and unlocks UI from loading.
                             * @param {Object} [preload] - will not fetch settings again
                             * if preloaded brand settings provided
                             */
                            store.dispatch(platformAction.LOAD_AGI_SETTINGS, { preload: localComponentData }).then(({ user }) => {
                                handleRouteMetaData(user && user.userUuid, store.state.platform.settings, { to, from, next });
                            });
                        }
                    });
            });
    } else {
        handleRouteMetaData(store.getters[authGetter.IS_AUTHENTICATED], store.state.platform.settings, { to, from, next });
    }
});

router.afterEach((to) => {
    if (!to.meta?.selfRedirect) {
        Vue.nextTick(() => {
            currentTimestamp = Number(history.state.key);
            router.app.$root.$emit('routeChanged');
        });
    }
});

function handleRouteMetaData(isAuthenticated, settings, { to, from, next }) {
    const { allowedDeviceType, moduleSwitch, redirect, skipBalanceCheck, forceBalanceCheck, requireAuth, requireRedirect } = to.meta || {};
    const UTMQuery = getUTMQueryObject({ to, from });
    const CasinoQuery = getCasinoQueryObject({ to, from });
    const redirectRoute = typeof redirect === 'function' ? redirect({ to, from, next }) : redirect;

    if (allowedDeviceType && !allowedDeviceType.includes(DEVICE_TYPE)) {
        if (redirect) {
            next({ ...redirectRoute, UTMQuery });
        } else {
            next({ path: routePath.HOMEPAGE, UTMQuery });
        }
        return;
    }
    if (moduleSwitch && !helper.pathChecker(moduleSwitch, settings)) {
        next({ path: routePath.HOMEPAGE, UTMQuery });
        return;
    }

    const shoudSentRequest = isAuthenticated && to.name !== routeName.LOGOUT && !isSibling(to.matched, from.path);

    if (shoudSentRequest && !skipBalanceCheck) {
        store.dispatch(authAction.GET_BALANCE, { force: !!forceBalanceCheck });
    }

    if (shoudSentRequest) {
        store.dispatch(messagingAction.GET_ONSITE_MESSAGES_AND_COUNT);
    }
    const redirectRequired = isAuthenticated
        ? (requireAuth !== undefined && !requireAuth) || requireRedirect
        : requireAuth || requireRedirect;
    applyQueryAndRedirect({ to, from, next }, UTMQuery, CasinoQuery, redirectRequired, redirectRoute);
}

function getUTMQueryObject({ to, from }) {
    const fromUTM = Object.keys(from.query).filter((queryParam) => queryParam.startsWith('utm'));
    const toUTM = Object.keys(to.query).filter((queryParam) => queryParam.startsWith('utm'));
    if (toUTM.length || !fromUTM.length) {
        return {};
    }
    return fromUTM.reduce(
        (UTMParams, queryParam) => ({
            ...UTMParams,
            [queryParam]: from.query[queryParam],
        }),
        {}
    );
}

function getCasinoQueryObject(route) {
    const allowableCasinoQuery = ['gameId', 'filter', 'favouriteCandidateGameId'];
    const fromCasino = Object.keys(route.from.query).filter((query) => allowableCasinoQuery.includes(query));
    const toCasino = Object.keys(route.to.query).filter((query) => allowableCasinoQuery.includes(query));
    if (toCasino.length || !fromCasino.length) {
        return {};
    }
    return fromCasino.reduce(
        (CasinoParams, queryParam) => ({
            ...CasinoParams,
            [queryParam]: route.from.query[queryParam],
        }),
        {}
    );
}

function applyQueryAndRedirect({ to, from, next }, UTMQuery, CasinoQuery, redirectRequired = false, redirectRoute = {}) {
    if (redirectRequired && redirectRoute.path) {
        redirectRoute.query = {
            ...redirectRoute.query,
            ...UTMQuery,
            ...to.query,
        };
        next(redirectRoute);
        return;
    }

    const { path: casinoPath } = store.getters[platformGetter.GET_BRAND_PREFERENCE].casinoType || {};

    const isCasinoQuery =
        Object.keys(CasinoQuery).length && from.path === routePath.LOGIN && to.path === `/${casinoPath || defaultCasinoPath}`;

    to.meta.selfRedirect = true;
    if (to.meta.isClickedBack) {
        next();
        return;
    }
    if (isCasinoQuery) {
        next({
            ...to,
            query: { ...to.query, ...CasinoQuery },
        });
    } else {
        delete to.meta.selfRedirect;
        next();
        if (from.meta?.skipDepositRedirect) {
            delete from.meta.skipDepositRedirect;
        }
    }
}

function handleScrollBehaviour(to, _, savedPosition) {
    const EXTRA_OFFSET = 100;
    this.app.$nextTick().then((app) => {
        if (to.hash || savedPosition || to.meta?.savedPosition) {
            app.$scroll.scrollTo(to.hash || savedPosition || to.meta?.savedPosition, { extraOffset: EXTRA_OFFSET });
        }
    });
}

export default router;
